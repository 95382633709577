$(".l-header_humburger_btn").click(function(){
	$(this).toggleClass('active');
	$(".l-header_humburger_nav").toggleClass('open');
});


// メニューをクリックされたら、非表示にする
$(".l-header_humburger_nav_inner li a").click(function(){
	$(".l-header_humburger_btn").removeClass('active');
	$(".l-header_humburger_nav").removeClass('open');
});


//スクロールした際の動きを関数でまとめる
function PageTopAnime() {
	var scroll = $(window).scrollTop();
	if (scroll >= 200){//上から200pxスクロールしたら
		$('#page-top').removeClass('DownMove');//#page-topについているDownMoveというクラス名を除く
		$('#page-top').addClass('UpMove');//#page-topについているUpMoveというクラス名を付与
	}else{
		if($('#page-top').hasClass('UpMove')){//すでに#page-topにUpMoveというクラス名がついていたら
			$('#page-top').removeClass('UpMove');//UpMoveというクラス名を除き
			$('#page-top').addClass('DownMove');//DownMoveというクラス名を#page-topに付与
		}
	}
}


// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	PageTopAnime();/* スクロールした際の動きの関数を呼ぶ*/
});


// ページが読み込まれたらすぐに動かしたい場合の記述
$(window).on('load', function () {
	PageTopAnime();/* スクロールした際の動きの関数を呼ぶ*/
});


// #page-topをクリックした際の設定
$('#page-top a').click(function () {
	$('body,html').animate({
        scrollTop: 0//ページトップまでスクロール
    }, 500);//ページトップスクロールの速さ。数字が大きいほど遅くなる
    return false;//リンク自体の無効化
});


// ヘッダーの色を変えるやつ
window.addEventListener("scroll", function () {
	var header = document.querySelector("header");
	header.classList.toggle("scroll-nav", window.scrollY > 0);
});


//スマホ用：スライダーをタッチしても止めずにスライドをさせたい場合
$('.p-top_mv_slider').on('touchmove', function(event, slick, currentSlide, nextSlide){
	$('.p-top_mv_slider').slick('slickPlay');
});


// 画面遷移
$(window).on('load',function(){
    $("#splash-logo").delay(0).fadeOut('slow');//ロゴを0.5秒でフェードアウトする記述
    //=====ここからローディングエリア（splashエリア）を0.5秒でフェードアウトした後に動かしたいJSをまとめる
    $("#splash").delay(0).fadeOut('slow',function(){//ローディングエリア（splashエリア）を秒でフェードアウトする記述
        $('body').addClass('appear');//フェードアウト後bodyにappearクラス付与
    });
    //=====ここまでローディングエリア（splashエリア）を1.5秒でフェードアウトした後に動かしたいJSをまとめる
   //=====ここから背景が伸びた後に動かしたいJSをまとめたい場合は
    $('.splashbg').on('animationend', function() {
        //この中に動かしたいJSを記載
    });
    //=====ここまで背景が伸びた後に動かしたいJSをまとめる
});

// スムーズスクロール
$('.l-header_humburger_nav_inner a[href*="#"]').click(function () {//全てのページ内リンクに適用させたい場合はa[href*="#"]のみでもOK
	var elmHash = $(this).attr('href'); //ページ内リンクのHTMLタグhrefから、リンクされているエリアidの値を取得
	var pos = $(elmHash).offset().top-250;//idの上部の距離からHeaderの高さを引いた値を取得
	$('body,html').animate({scrollTop: pos}, 1500); //取得した位置にスクロール。500の数値が大きくなるほどゆっくりスクロール
	return false;
});
$('.l-header_nav_inner a[href*="#"]').click(function () {//全てのページ内リンクに適用させたい場合はa[href*="#"]のみでもOK
	var elmHash = $(this).attr('href'); //ページ内リンクのHTMLタグhrefから、リンクされているエリアidの値を取得
	var pos = $(elmHash).offset().top-100;//idの上部の距離からHeaderの高さを引いた値を取得
	$('body,html').animate({scrollTop: pos}, 1000); //取得した位置にスクロール。500の数値が大きくなるほどゆっくりスクロール
	return false;
});

// スライダー（フェードインフェードアウト）
$('.p-top_mv_slider').slick({
		fade:true,//切り替えをフェードで行う。初期値はfalse。
		autoplay: true,//自動的に動き出すか。初期値はfalse。
		autoplaySpeed: 3000,//次のスライドに切り替わる待ち時間
		speed:1500,//スライドの動きのスピード。初期値は300。
		infinite: true,//スライドをループさせるかどうか。初期値はtrue。
		slidesToShow: 1,//スライドを画面に1枚見せる
		slidesToScroll: 1,//1回のスクロールで1枚の写真を移動して見せる
        pauseOnFocus: false,//フォーカスで一時停止を無効
        pauseOnHover: false,//マウスホバーで一時停止を無効
        pauseOnDotsHover: false,//ドットナビゲーションをマウスホバーで一時停止を無効
        arrows: false,
        dots: false,
        responsive: [
        {
        breakpoint: 769, //768px以下のサイズに適用
        settings: {
        speed:2200,//スライドの動きのスピード。初期値は300。
        autoplaySpeed: 4000,//次のスライドに切り替わる待ち時間
    },
},
],
});